.invis-wrapper {
	display: grid;
	grid-column: 1 / 5;
	margin: 10px 1vw 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.invis-wrapper label {
	margin-left: 10px;
	margin-bottom: 10px;
}

#radio-control {
	margin-top: 20px;
	margin-bottom: 10px;
}

#radio-group {
	flex-direction: row;
}
